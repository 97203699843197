<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6 my-2">
            <div class="card h-100">
              <div class="card-header d-flex">
                <h5 class="mb-0 text-capitalize text-primary">
                  Change Password
                </h5>
              </div>
              <div class="card-body pt-0">
                <form id="changePassword">
                  <label class="h5 text-bold" for="username">Username: <span class="text-primary">{{ Username }}</span></label>
                  <input type="hidden" name="username" id="username"  v-model.trim="Username"  />

                  <div class="col-12">
                    <div>
                      <h6>
                        รหัสผ่านปัจจุบัน
                        <span class="text-danger text-lg">*</span>
                      </h6>
                    </div>
                    <input
                      id="CurrentPassword"
                      type="password"
                      autocomplete="current-password"
                      class="form-control form-control-lg"
                      min="6"
                      maxlength="50"
                      v-model.trim="CurrentPassword"
                    />
                    <div v-if="isShowAlertCurrentPassword">
                      <span class="text-danger text-sm"
                        >รหัสผ่านจะต้องมีความยาวอย่างน้อย 8 ตัวอักษร ไม่เกิน 50
                        ตัวอักษร</span
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <div>
                      <h6>
                        รหัสผ่านใหม่
                        <span class="text-danger text-lg">*</span>
                      </h6>
                    </div>
                    <input
                      id="NewPassword"
                      type="password"
                      autocomplete="new-password"
                      class="form-control form-control-lg"
                      min="6"
                      maxlength="50"
                      v-model.trim="NewPassword"
                    />
                    <div v-if="isShowAlertNewPassword">
                      <span class="text-danger text-sm"
                        >รหัสผ่านใหม่จะต้องมีความยาวอย่างน้อย 6 ตัวอักษร ไม่เกิน
                        50 ตัวอักษร</span
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <div>
                      <h6>
                        ยืนยันรหัสผ่านใหม่
                        <span class="text-danger text-lg">*</span>
                      </h6>
                    </div>
                    <input
                      id="ConfirmNewPassword"
                      type="password"
                      autocomplete="new-password"
                      class="form-control form-control-lg"
                      min="6"
                      maxlength="50"
                      v-model.trim="ConfirmNewPassword"
                    />
                    <div v-if="isShowAlertConfirmNewPassword">
                      <span class="text-danger text-sm"
                        >ข้อมูลยืนยันรหัสผ่านใหม่
                        ไม่ตรงกับข้อมูลรหัสผ่านใหม่</span
                      >
                    </div>

                    <!-- button -->
                    <div class="button-row d-flex mt-4">
                      <argon-button
                        type="button"
                        color="light"
                        variant="gradient"
                        class="js-btn-prev"
                        @click="clear"
                        >ล้างข้อมูล</argon-button
                      >
                      <argon-button
                        type="button"
                        color="primary"
                        variant="gradient"
                        class="ms-auto"
                        @click="checkSavePassword"
                        >บันทึกข้อมูล</argon-button
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"
import { ref, onMounted } from "vue"
import { useForm, useField } from "vee-validate"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { object, string, lazy } from "yup"
import Swal from "sweetalert2"

import ArgonLabel from "@/components/ArgonLabel.vue"
import ArgonButton from "@/components/ArgonButton.vue"
import ArgonAlert from "@/components/ArgonAlert.vue"

export default {
  name: "ChangePassword",
  components: {
    ArgonButton,
    ArgonAlert,
    ArgonLabel,
  },
  data() {
    return {}
  },
  setup(props, ctx) {
    const { t } = useI18n({})
    const router = useRouter()
    const Username = JSON.parse(localStorage.getItem("curAuthen")).name

    var CurrentPassword = ref(null)
    var NewPassword = ref(null)
    var ConfirmNewPassword = ref(null)
    var isShowAlertCurrentPassword = ref(false)
    var isShowAlertNewPassword = ref(false)
    var isShowAlertConfirmNewPassword = ref(false)

    const { handleSubmit } = useForm({
      validationSchema: schema,
    })

    onMounted(() => {
    })

    function unAuthorized() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }

    const schema = lazy(() =>
      object({
        NewPassword: string(),
        // .required(() => t("errors.required", { field: t("NewPassword") }))
        // .min(18, t("errors.min", { length: 18 }))
        // .max(50, t("errors.max", { length: 50 })),
      })
    )

    function checkCurrentPassword() {
      if (CurrentPassword.value == null || CurrentPassword.value == "") {
        isShowAlertCurrentPassword.value = true
        return false
      } else {
        if (CurrentPassword.value.length < 6) {
          isShowAlertCurrentPassword.value = true
          return false
        } else if (CurrentPassword.value.length >= 50) {
          isShowAlertCurrentPassword.value = true
          return false
        } else {
          isShowAlertCurrentPassword.value = false
          return true
        }
      }
    }

    function checkNewPassword() {
      if (NewPassword.value == null || NewPassword.value == "") {
        isShowAlertNewPassword.value = true
        return false
      } else {
        if (NewPassword.value.length < 6) {
          isShowAlertNewPassword.value = true
          return false
        } else if (NewPassword.value.length >= 50) {
          isShowAlertNewPassword.value = true
          return false
        } else {
          isShowAlertNewPassword.value = false
          return true
        }
      }
    }

    function checkConfirmNewPasswork() {
      if (ConfirmNewPassword.value == null || ConfirmNewPassword.value == "") {
        isShowAlertConfirmNewPassword.value = true
        return false
      } else {
        if (ConfirmNewPassword.value != NewPassword.value) {
          isShowAlertConfirmNewPassword.value = true
          return false
        } else {
          isShowAlertConfirmNewPassword.value = false
          return true
        }
      }
    }

    function checkSavePassword() {
      checkCurrentPassword()
      checkNewPassword()
      checkConfirmNewPasswork()
      if (
        checkCurrentPassword() &&
        checkNewPassword() &&
        checkConfirmNewPasswork()
      ) {
        saveChangePassword()
      }
    }

    function focusOnCurrentPassword() {
      const el = document.getElementById("CurrentPassword")
      el.focus()
    }

    const saveChangePassword = handleSubmit((values) => {
      const token = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      if (uid) {
        const myObjects = {
          AuthUserID: Number(uid),
          PasswordCurrent: CurrentPassword.value,
          Password: NewPassword.value,
          UpdatedBy: uid,
        }
        let res = axios({
          method: "post",
          url: "passUpdateUser",
          data: myObjects,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            if (response.status == 226) {
              showAlertCurrentPasswordNotCorrect()
              focusOnCurrentPassword()
              CurrentPassword.value = ""
              //รหัสผ่านปัจจุบันไม่ถูกต้อง
            } else {
              showAlertSuccess()
              clear()
              //บันทึกข้อมูลเรียบร้อยแล้ว
            }
            return
          })
          .catch((error) => {
            if (error.response.status === 401) {
              unAuthorized()
              return
            }
            showAlertError()
            return false
          })
          .then((rs) => {
            return rs // for await purpose
          })
        return res
        //save
      }
    }, onInvalidSubmit)

    let isInvalidSubmit = false
    function onInvalidSubmit({ values, errors, results }) {
      isInvalidSubmit = true
    }

    function clear() {
      CurrentPassword.value = ""
      NewPassword.value = ""
      ConfirmNewPassword.value = ""
    }

    function showAlertConfirmChangePassword() {
      Swal.fire({
        title: "ยืนยันเปลี่ยนรหัสผ่าน?",
        text:
          "คุณต้องการยืนยันเปลี่ยนรหัสผ่านใช่หรือไม่? หากยืนยันแล้วระบบจะทำการออกจากระบบให้ท่านเข้าสู่ระบบใหม่อีกครั้ง",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน, เปลี่ยนรหัสผ่าน!",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          saveChangePassword()
        }
      })
    }

    function showAlertCurrentPasswordNotCorrect() {
      Swal.fire("Error!", "รหัสผ่านปัจจุบันไม่ถูกต้อง! ", "")
    }

    function showAlertError() {
      Swal.fire("Error!", "เกิดข้อผิดพลาด ไม่สามารถทำรายการได้! ", "")
    }

    function Logout() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }

    function showAlertSuccess() {
      Swal.fire(
        "Success!",
        "บันทึกข้อมูลเรียบร้อยแล้ว! กรุณา Logout แล้ว Login ใหม่เพื่อใช้งานระบบ ",
        ""
      ).then((result) => {
        if (result.isConfirmed) {
          Logout()
        }
      })
    }

    return {
      t,
      clear,
      checkSavePassword,
      CurrentPassword,
      NewPassword,
      ConfirmNewPassword,
      isShowAlertCurrentPassword,
      isShowAlertNewPassword,
      isShowAlertConfirmNewPassword,
      Username,
    }
  },
}
</script>
