<template>
  <nav
    id="navbarBlur"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${
            darkMode ? 'bg-default' : 'bg-white'
          } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />
      <div
        class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none"
        :class="isRTL ? 'me-3' : ''"
      >
        <a
          href="#"
          class="p-0 nav-link text-body"
          @click.prevent="navbarMinimize"
        >
          <div class="sidenav-toggler-inner">
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
          </div>
        </a>
      </div>
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        ></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <h6 class="text-white fw-bold">
              <div class="sidenav-toggler-inner d-none">
                <span class="text-sm">ชื่อผู้ใช้งาน: </span>
              </div>

              {{ username }}
            </h6>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="p-0 nav-link text-white"
              @click.prevent="navbarMinimize"
            >
              <div class="sidenav-toggler-inner">
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "@/examples/Breadcrumbs.vue"
import { mapState, mapMutations, mapActions } from "vuex"
import ArgonAvatar from "@/components/ArgonAvatar.vue"
import userimg from "@/assets/img/marie.jpg"

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
    ArgonAvatar,
  },
  data() {
    return {
      showMenu: false,
      userimg,
      username: "",
    }
  },
  computed: {
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),
    currentRouteName() {
      let routeName = JSON.parse(localStorage.getItem("perms"))
      let name = ""
      for (let r in routeName) {
        if (routeName[r].AppModel == this.$route.path) {
          name = routeName[r].AppNotes
        }
      }
      return name
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1]
      return dir.charAt(0).toUpperCase() + dir.slice(1)
    },
  },
  created() {
    this.minNav
  },
  mounted() {
    var w = window.innerWidth
    if (w < 821) {
      this.navbarMinimize()
    }
    this.$nextTick(() => {
      if (JSON.parse(localStorage.getItem("curAuthen"))) {
        if (JSON.parse(localStorage.getItem("curAuthen")).token) {
          this.username = JSON.parse(localStorage.getItem("curAuthen")).username
          let fullname = JSON.parse(localStorage.getItem("curAuthen")).fullname
          let type = JSON.parse(localStorage.getItem("curAuthen")).typecode
          this.username = fullname
        } else {
          this.$router.push({ name: "SigninUser" })
        }
      } else {
        this.$router.push({ name: "SigninUser" })
      }
    }),
      this.$router.beforeEach((to, from, next) => {
        //เช็คสิทธิ์การเข้าถึงหน้า
        if (to.name == "SplashScreen" || to.name == "SplashScreenMobile") {
          //web
          if (to.params.id != "") {
            //use fullpath
            var str = to.path
            var pyactive = ""

            if (to.name == "SplashScreenMobile") {
              //mobile
              pyactive = str.split("welcomeMobile/")[1]
            } else {
              pyactive = str.split("welcome/")[1]
            }
            localStorage.setItem("pyactive", pyactive)
          }
          //end added
        } else {
          this.checkPermis(to, from, next)
        }
      })
  },

  Logout() {
    localStorage.removeItem("curAuthen")
    localStorage.removeItem("perms")
    localStorage.removeItem("pyactive")
    router.push("/login")
  },

  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),
    checkPermis(to, from, next) {
      let routeName = JSON.parse(localStorage.getItem("perms"))
      let name = ""
      for (let r in routeName) {
        if (routeName[r].AppModel == to.path) {
          name = routeName[r].AppNotes
        }
      }
      if (to.path == "/login") {
        name = "SigninUser"
        let curAuthen = JSON.parse(localStorage.getItem("curAuthen"))
        if (curAuthen) {
          if (curAuthen.token) {
            name = "Main"
            if (curAuthen.groups == "STA3") {
              name = "FillProduct"
            }
          } else {
            name = "SigninUser"
          }
        } else {
          name = "SigninUser"
        }
      }
      if (to.path == "/error404") {
        name = "Error404"
      }

      if (name == "") {
        console.log("ไม่มีสิทธิ์เข้าถึงหน้านี้")
        // this.$router.push("/error404")
      } else {
        next()
        // minimize menu
        var w = window.innerWidth
        if (w < 821) {
          this.navbarMinimize()
        }
      }
    },
  },
}
</script>
