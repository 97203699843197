<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-30 pt-2 pb-9"
      :style="{ backgroundImage: 'url(' + bglogin + ')' }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">TCCC</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card mt-5">
            <div class="card-header pb-0 text-start">
              <h3 class="font-weight-bolder">Station2 & Station3</h3>
              <p class="mb-0">กรุณา Login เพื่อเข้าสู่ระบบ</p>
            </div>
            <div class="card-body">
              <div v-show="status === 401">
                <argon-alert color="warning">
                  <strong>Oops!</strong>
                  {{ $t("errors.statusUnauthorizedPassword") }}
                </argon-alert>
              </div>
              <div v-show="status === 404">
                <argon-alert color="danger">
                  <strong>Oops!</strong>
                  ไม่มี Username นี้ในระบบ
                </argon-alert>
              </div>
              <div v-show="status === 405">
                <argon-alert color="danger">
                  <strong>Oops!</strong>
                  อุปกรณ์เครื่องนี้ ไม่มีสิทธิ์ใช้งานระบบ
                </argon-alert>
              </div>
              <div v-show="status === 406">
                <argon-alert color="danger">
                  <strong>Oops!</strong>
                  อุปกรณ์เครื่องนี้ ไม่มีสิทธิ์ใช้งานระบบ
                </argon-alert>
              </div>
              <div v-show="status === 407">
                <argon-alert color="danger">
                  <strong>Oops!</strong>
                  รหัสผ่านไม่ถูกต้อง
                </argon-alert>
              </div>
              <form @submit="onSubmit">
                <label>Username</label>
                <argon-input
                  id="username"
                  placeholder="Username"
                  name="username"
                  size="lg"
                  :errorMessage="usernameError"
                  v-model="username"
                />
                <label>Password</label>
                <argon-input
                  id="password"
                  type="password"
                  placeholder="Password"
                  name="password"
                  size="lg"
                  autocomplete="current-password"
                  :errorMessage="passwordError"
                  v-model="password"
                />

                <div class="text-center">
                  <argon-button color="primary" full-width class="mt-4 mb-0"
                    >Login</argon-button
                  >
                </div>
              </form>
            </div>
            <div class="card-footer text-center pt-0 px-lg-2 px-1">
              <p class="mb-4 text-sm mx-auto">
                หากยังไม่มี Username และ Password
                <span class="text-primary font-weight-bold"
                  >กรุณาติดต่อเจ้าหน้าที่</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ref } from "vue"
import { useForm, useField } from "vee-validate"
import { useRouter } from "vue-router"
import { object, string } from "yup"
import axios from "axios"
import bglogin from "@/assets/img/signin-cover-4.png"

import ArgonInput from "@/components/ArgonInput.vue"
import ArgonSwitch from "@/components/ArgonSwitch.vue"
import ArgonButton from "@/components/ArgonButton.vue"
import ArgonAlert from "@/components/ArgonAlert.vue"
const body = document.getElementsByTagName("body")[0]
import { mapMutations } from "vuex"

export default {
  name: "SigninUser",
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
    ArgonAlert,
  },
  data() {
    return {
      bglogin,
      file: null,
      content: null,
    }
  },
  setup() {
    var status = ref(0)
    var macaddress = ref("")
    var isAuthen = ref(false)

    var errorStatus = false
    const router = useRouter()

    const schema = object({
      username: string().required().min(4),
      password: string().required().min(6),
    })

    checkAuthen()
    function checkAuthen() {
      let curAuthen = JSON.parse(localStorage.getItem("curAuthen"))
      if (curAuthen) {
        var name = "Main"
        if (curAuthen.token) {
          name = "Main"
          if (curAuthen.groups == "STA3") {
            name = "FillProduct"
          }
        } else {
          name = "SigninUser"
        }
      } else {
        name = "SigninUser"
      }
      router.push({ name: name })
    }

    const { handleSubmit } = useForm({
      validationSchema: schema,
    })

    const { value: username, errorMessage: usernameError } = useField(
      "username"
    )
    const { value: password, errorMessage: passwordError } = useField(
      "password"
    )

    const onSubmit = handleSubmit((values) => {
      let mac = localStorage.getItem("pyactive")
      const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlN1cGVydXNlciIsImV4cCI6MTczMzc0OTI4N30.ji0GGKffrDXpHzAWwIaa61x1hzyz_b6Hgd8qSuxRoOg"
      if (mac) {
        macaddress.value = mac
      }

      var loginObjects = {
        username: values.username,
        password: values.password,
        macaddress: macaddress.value,
      }
      axios({
        method: "post",
        url: "v1/signin",
        data: loginObjects,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          var r = response.data.data
          localStorage.setItem(
            "curAuthen",
            JSON.stringify({
              name: r.username,
              fullname: r.fullname,
              token: r.token,
              isAuthenticated: true,
              comp: r.comp,
              typecode: r.typecode,
              uid: r.uid,
              tid: r.tid,
              groups: r.groups,
            })
          )
          localStorage.setItem("perms", JSON.stringify(response.data.perms))
          localStorage.removeItem("pyactive")
          if (r.groups == "STA3") {
            router.push({ name: "FillProduct" })
          } else {
            router.push({ name: "Main" })
          }
        })
        .catch((error) => {
          status.value = error.response.status
        })
    }, onInvalidSubmit)

    //if failed - for check data only
    function onInvalidSubmit({ values, errors, results }) {
      console.log(errors) // a map of field names and their first error message
    }

    return {
      username,
      usernameError,
      password,
      passwordError,
      onSubmit,
      errorStatus,
      status,
      macaddress,
      isAuthen,
    }
  },
  created() {
    this.$store.state.hideConfigButton = true
    this.toggleDefaultLayout()
    body.classList.remove("bg-gray-100")
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false
    this.toggleDefaultLayout()
    body.classList.add("bg-gray-100")
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
  },
}
</script>
<style>
div.mcshow {
  visibility: hidden;
}
</style>
